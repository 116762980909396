import React, { useEffect, useState, useContext } from 'react'
import IAddress from '../../server/src/Interfaces/IAddress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import IFireplace from '../../server/src/Interfaces/IFireplace'
import ky from 'ky'
import IAddressCategory from '../../server/src/Interfaces/IAddressCategory'
import IAddressType from '../../server/src/Interfaces/IAddressType'
import toast from 'react-hot-toast'
import { GlobalContext } from '../App'
import { updateFireplaces, updateAddressTypes, updateAddressCategories } from '../Modules/Data'
import { API_URL } from '../Globals'

const AddCustomer: React.FC = () => {
    const { globalVariable, setGlobalVariable } = useContext(GlobalContext)
    const [newAddressForm, setNewAddressForm] = useState<IAddress>({
        _id: '',
        firstname: '',
        lastname: '',
        address: '',
        zip: '',
        city: '',
        lastSweepDate: '',
        nextSweepDate: '',
        phoneNumbers: [],
        additionalInfo: '',
        selectedFireplaceIds: [],
        addressType: 0,
        addressCategoryId: 1
    })
    const [fireplaceIds, setFireplaceIds] = useState<IFireplace[]>([])
    const [addressCategories, setAddressCategories] = useState<IAddressCategory[]>([])
    const [addressTypes, setAddressTypes] = useState<IAddressType[]>([])

    useEffect(() => {
        updateFireplaces(globalVariable.sessionToken, setFireplaceIds)
        updateAddressCategories(globalVariable.sessionToken, setAddressCategories)
        updateAddressTypes(globalVariable.sessionToken, setAddressTypes)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createAddress = () => {
        ky.post(API_URL + '/api/address/create', {
            json: newAddressForm,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + globalVariable.sessionToken,
                'Token': localStorage.getItem('token') as string
            }
        }).then((data) => {
            if (data.status === 200) {
                toast.success('Uusi kohde lisätty')
                onAddComplete()
            }
        }).catch((error) => {
            console.log(error)
            toast.error('Virhe kohteen lisäämisessä', { duration: 10000 })
        })
    }

    const onAddComplete = () => {
        setGlobalVariable({
            ...globalVariable,
            menuSelection: 'customers'
        })
    }

    return (
        <div className="container mx-auto mb-10">
            <div className="p-6 sm:p-10 bg-gradient-to-r from-blue-100 to-indigo-100 border border-blue-300 rounded-xl shadow-2xl">
                <h2 className="text-2xl font-semibold text-blue-900 mb-8">Uusi Kohde</h2>
                <form className="space-y-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                        <div>
                            <label htmlFor="firstname" className="block text-sm font-medium text-gray-700 mb-2">Etunimi</label>
                            <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                onChange={(e) => setNewAddressForm({ ...newAddressForm, firstname: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                            />
                        </div>
                        <div>
                            <label htmlFor="lastname" className="block text-sm font-medium text-gray-700 mb-2">Sukunimi</label>
                            <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                onChange={(e) => setNewAddressForm({ ...newAddressForm, lastname: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                            />
                        </div>
                        <div>
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-2">Osoite</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                onChange={(e) => setNewAddressForm({ ...newAddressForm, address: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                            />
                        </div>
                        <div>
                            <label htmlFor="zip" className="block text-sm font-medium text-gray-700 mb-2">Postinumero</label>
                            <input
                                type="text"
                                id="zip"
                                name="zip"
                                onChange={(e) => setNewAddressForm({ ...newAddressForm, zip: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                            />
                        </div>
                        <div>
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-2">Paikkakunta</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                onChange={(e) => setNewAddressForm({ ...newAddressForm, city: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                            />
                        </div>
                        <div>
                            <label htmlFor="lastSweepDate" className="block text-sm font-medium text-gray-700 mb-2">Viimeisin nuohous</label>
                            <input
                                type="date"
                                id="lastSweepDate"
                                name="lastSweepDate"
                                onChange={(e) => setNewAddressForm({ ...newAddressForm, lastSweepDate: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                            />
                        </div>
                        <div>
                            <label htmlFor="nextSweepingDate" className="block text-sm font-medium text-gray-700 mb-2">Seuraava nuohous</label>
                            <input
                                type="date"
                                id="nextSweepingDate"
                                name="nextSweepingDate"
                                onChange={(e) => setNewAddressForm({ ...newAddressForm, nextSweepDate: e.target.value })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                            />
                        </div>
                        <div className="sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Puhelinnumerot</label>
                            {newAddressForm.phoneNumbers.map((phoneNumber, index) => (
                                <div key={index} className="flex items-center mb-3">
                                    <input
                                        type="text"
                                        value={phoneNumber}
                                        onChange={(e) => {
                                            const newPhoneNumbers = [...newAddressForm.phoneNumbers]
                                            newPhoneNumbers[index] = e.target.value
                                            setNewAddressForm({ ...newAddressForm, phoneNumbers: newPhoneNumbers })
                                        }}
                                        className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const newPhoneNumbers = newAddressForm.phoneNumbers.filter((_, i) => i !== index)
                                            setNewAddressForm({ ...newAddressForm, phoneNumbers: newPhoneNumbers })
                                        }}
                                        className="ml-3 px-3 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-200 ease-in-out"
                                    >
                                        <span className='whitespace-nowrap'><FontAwesomeIcon icon={faTrash} /> Poista</span>
                                    </button>
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={() => {
                                    const newPhoneNumbers = [...newAddressForm.phoneNumbers, '']
                                    setNewAddressForm({ ...newAddressForm, phoneNumbers: newPhoneNumbers })
                                }}
                                className="mt-3 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200 ease-in-out"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Lisää puhelinnumero
                            </button>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="additionalInfo" className="block text-sm font-medium text-gray-700 mb-2">Lisätiedot</label>
                            <textarea
                                id="additionalInfo"
                                name="additionalInfo"
                                onChange={(e) => setNewAddressForm({ ...newAddressForm, additionalInfo: e.target.value })}
                                rows={4}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                            ></textarea>
                        </div>
                        <div className="sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Tulisijat</label>
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                                {fireplaceIds.map((fireplace: IFireplace) => (
                                    <div key={fireplace._id} className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id={`fireplace-${fireplace.id}`}
                                            name="fireplaces"
                                            value={fireplace._id}
                                            onChange={(e) => {
                                                const newSelectedFireplaceIds = [...newAddressForm.selectedFireplaceIds]
                                                if (e.target.checked) {
                                                    newSelectedFireplaceIds.push(fireplace.id)
                                                } else {
                                                    newSelectedFireplaceIds.splice(newSelectedFireplaceIds.indexOf(fireplace.id), 1)
                                                }
                                                setNewAddressForm({ ...newAddressForm, selectedFireplaceIds: newSelectedFireplaceIds })
                                            }}
                                            className="form-checkbox h-5 w-5 text-blue-600 transition duration-200 ease-in-out rounded border-gray-300 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        />
                                        <label htmlFor={`fireplace-${fireplace._id}`} className="ml-3 block text-sm font-medium text-gray-700 select-none">
                                            {fireplace.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label htmlFor="addressType" className="block text-sm font-medium text-gray-700 mb-2">Osoitetyyppi</label>
                            <select
                                id="addressType"
                                name="addressType"
                                onChange={(e) => setNewAddressForm({ ...newAddressForm, addressType: parseInt(e.target.value) })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                            >
                                {addressTypes.map((type) => (
                                    <option key={type.id} value={type.id}>
                                        {type.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="addressCategoryId" className="block text-sm font-medium text-gray-700 mb-2">Osoitekategoria</label>
                            <select
                                id="addressCategoryId"
                                name="addressCategoryId"
                                value={newAddressForm.addressCategoryId}
                                onChange={(e) => setNewAddressForm({ ...newAddressForm, addressCategoryId: parseInt(e.target.value) })}
                                className="w-full px-4 py-2 rounded-lg border-gray-300 bg-white focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 transition duration-200 ease-in-out"
                            >
                                {addressCategories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="mt-10 flex flex-col sm:flex-row justify-end space-y-4 sm:space-y-0 sm:space-x-4">
                        <button
                            onClick={createAddress}
                            type="button"
                            className="w-full sm:w-auto px-6 py-3 bg-blue-600 text-white font-medium rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200 ease-in-out"
                        >
                            Lisää
                        </button>
                        <button
                            type="button"
                            onClick={onAddComplete}
                            className="w-full sm:w-auto px-6 py-3 bg-red-600 text-white font-medium rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition duration-200 ease-in-out"
                        >
                            Peruuta
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddCustomer
