import React, { useState, useEffect } from 'react'
import './Landing.css'

const Landing: React.FC = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const [gradientPosition, setGradientPosition] = useState({ x: 0, y: 0 })
  const [hexagons, setHexagons] = useState<Array<{ x: number; y: number; originalX: number; originalY: number }>>([])

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY })
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  useEffect(() => {
    const smoothenPosition = () => {
      const damping = 0.02
      const dx = mousePosition.x - gradientPosition.x
      const dy = mousePosition.y - gradientPosition.y
      setGradientPosition({
        x: gradientPosition.x + dx * damping,
        y: gradientPosition.y + dy * damping
      })
    }

    const animationFrame = requestAnimationFrame(smoothenPosition)

    return () => {
      cancelAnimationFrame(animationFrame)
    }
  }, [mousePosition, gradientPosition])

  useEffect(() => {
    const createHexagons = () => {
      const newHexagons = []
      for (let i = 0; i < 20; i++) {
        const x = Math.random() * window.innerWidth
        const y = Math.random() * window.innerHeight
        newHexagons.push({
          x: x,
          y: y,
          originalX: x,
          originalY: y
        })
      }
      setHexagons(newHexagons)
    }

    createHexagons()
  }, [])

  useEffect(() => {
    const animateHexagons = () => {
      setHexagons(prevHexagons =>
        prevHexagons.map(hexagon => {
          const dx = mousePosition.x - hexagon.x
          const dy = mousePosition.y - hexagon.y
          const distance = Math.sqrt(dx * dx + dy * dy)

          if (distance < 200) {
            const angle = Math.atan2(dy, dx)
            const targetX = hexagon.x - Math.cos(angle) * 200
            const targetY = hexagon.y - Math.sin(angle) * 200
            return {
              ...hexagon,
              x: hexagon.x + (targetX - hexagon.x) * 0.05,
              y: hexagon.y + (targetY - hexagon.y) * 0.05
            }
          } else {
            return {
              ...hexagon,
              x: hexagon.x + (hexagon.originalX - hexagon.x) * 0.05,
              y: hexagon.y + (hexagon.originalY - hexagon.y) * 0.05
            }
          }
        })
      )
    }

    const animationFrame = requestAnimationFrame(animateHexagons)

    return () => {
      cancelAnimationFrame(animationFrame)
    }
  }, [mousePosition])

  const gradientStyle = {
    background: `radial-gradient(circle at ${gradientPosition.x}px ${gradientPosition.y}px, rgba(59, 130, 246, 0.8) 0%, rgba(147, 51, 234, 0.8) 100%)`,
  }

  return (
    <div className="min-h-screen flex items-center justify-center relative overflow-hidden">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="absolute inset-0" style={gradientStyle}></div>
      {hexagons.map((hexagon, index) => (
        <div
          key={index}
          className="absolute w-8 h-8 bg-white opacity-20 rounded-full"
          style={{
            left: `${hexagon.x}px`,
            top: `${hexagon.y}px`,
            transform: 'rotate(30deg)',
            clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)'
          }}
        ></div>
      ))}
      <div className="w-full max-w-6xl mx-auto text-center px-4 relative z-10">
        <img src="/hexacat.svg" alt="Hexacat Logo" className="mx-auto mb-8 w-40 h-40 sm:w-48 sm:h-48 md:w-64 md:h-64 filter invert drop-shadow-lg" />
        <h1 className="text-3xl sm:text-5xl md:text-6xl font-extrabold text-white mb-3 sm:mb-6 leading-tight hexacat drop-shadow-lg">Hexacat</h1>
        <p className="text-base sm:text-xl md:text-2xl text-white mb-6 sm:mb-12 max-w-3xl mx-auto drop-shadow-md description">Voimanlähteenä <u>React.js</u>, <u>Express.js</u> sekä <u>MongoDB</u></p>
      </div>
      <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2 text-white text-xs opacity-50">
        License: Trial License
      </div>
    </div>
  )
}

export default Landing