import ky from "ky"
import { API_URL } from "../Globals"
import IUser from "../../server/src/Interfaces/IUser"

const updateFireplaces = async (sessionToken: string | undefined, setFireplaceIds: any) => {
    ky.get(`${API_URL}/api/fireplace/all`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionToken,
            'Token': localStorage.getItem('token')?.toString()
        }
    }).then((data) => {
        data.text().then((data) => {
            setFireplaceIds(JSON.parse(data))
        })
    }).catch((error) => {
        console.log(error)
    })
}

const updateAddressCategories = async (sessionToken: string | undefined, setAddressCategories: any) => {
    ky.get(`${API_URL}/api/address/category/all`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionToken,
            'Token': localStorage.getItem('token')?.toString()
        }
    }).then((data) => {
        data.text().then((data) => {
            setAddressCategories(JSON.parse(data))
        })
    }).catch((error) => {
        console.log(error)
    })
}

const updateAddressTypes = async (sessionToken: string | undefined, setAddressTypes: any) => {
    ky.get(`${API_URL}/api/address/type/all`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionToken,
            'Token': localStorage.getItem('token')?.toString()
        }
    }).then((data) => {
        data.text().then((data) => {
            setAddressTypes(JSON.parse(data))
        })
    }).catch((error) => {
        console.log(error)
    })
}

const updateUsers = async (sessionToken: string | undefined, setUsers: any) => {
    ky.get(`${API_URL}/api/user/all`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionToken,
            'Token': localStorage.getItem('token')?.toString()
        }
    }).then((data) => {
        data.text().then((data) => {
            setUsers(JSON.parse(data))
        })
    }).catch((error) => {
        console.log(error)
    })
}

const updatePermissions = async (sessionToken: string | undefined, addressId: string, setPermissions: any) => {
    ky.get(`${API_URL}/api/address/permission/${addressId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionToken,
            'Token': localStorage.getItem('token')?.toString()
        }
    }).then((data) => {
        data.text().then((data) => {
            setPermissions(JSON.parse(data))
        })
    }).catch((error) => {
        console.log(error)
    })
}

const removePermission = async (sessionToken: string | undefined, permissionId: string): Promise<boolean> => {
    try {
        const response = await ky.delete(`${API_URL}/api/address/permission/${permissionId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionToken,
                'Token': localStorage.getItem('token')?.toString()
            }
        })
        return response.status === 200
    } catch (error) {
        console.log(error)
        return false
    }
}

const renewToken = async () => {
    await ky.get(`${API_URL}/api/renewToken`, {
        headers: {
            'Content-Type': 'application/json',
            'Token': localStorage.getItem('token')?.toString()
        }
    }).then(async (response) => {
        const data: IUser = await response.json();

        localStorage.setItem('sessionToken', data.sessionToken || '')
        localStorage.setItem('username', data.username || '')
        localStorage.setItem('firstName', data.firstName || '')
        localStorage.setItem('lastName', data.lastName || '')
        localStorage.setItem('sessionValid', 'true')
        localStorage.setItem('permissions', JSON.stringify(data.permissions) || '')
        localStorage.setItem('token', data.token || '')

        const currentTime = new Date()
        const newTime = new Date(currentTime.getTime() + 12 * 60 * 60 * 1000)
        localStorage.setItem('expireAt', newTime.toISOString())
    }).catch((error) => {
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.href = '/login'
        }
    })
}

export { updateFireplaces, updateAddressCategories, updateAddressTypes, updateUsers, updatePermissions, removePermission, renewToken }